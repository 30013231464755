import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-center font-medium text-gray-900" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = {
  key: 2,
  class: "mt-10 flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseAlert = _resolveComponent("BaseAlert")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!

  return (_openBlock(), _createBlock(_component_LoginLayout, null, {
    default: _withCtx(() => [
      (_ctx.passwordResetSent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('platform.weak-password.confirmation-message')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.forceReset)
              ? (_openBlock(), _createBlock(_component_BaseAlert, {
                  key: 0,
                  color: "danger",
                  icon: "halt"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('platform.weak-password.weak-password')), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('platform.weak-password.warning-message-require-reset')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_BaseAlert, {
                  key: 1,
                  color: "alert",
                  icon: "warning"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('platform.weak-password.weak-password')), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('platform.weak-password.warning-message', [_ctx.toDate(_ctx.enforceDate).format('D MMMM YYYY')])), 1)
                  ]),
                  _: 1
                })),
            _createVNode(_component_BaseButton, {
              class: "flex mt-4 text-center btn-large w-full",
              loading: _ctx.loading,
              onClick: _ctx.requestResetPassword
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.weak-password.request-password-reset')), 1)
              ]),
              _: 1
            }, 8, ["loading", "onClick"]),
            (!_ctx.forceReset)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: { name: _ctx.redirect, props: { organisationId: _ctx.organisationId } },
                    class: "text-primary-500 hover:text-primary-900"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.weak-password.not-now')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }))
}